let dayjs = require('dayjs')
let utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
let relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime, {thresholds : [
        { l: 's', r: 1 },
        { l: 'm', r: 1 },
        { l: 'mm', r: 59, d: 'minute' },
        { l: 'h', r: 1 },
        { l: 'hh', r: 23, d: 'hour' },
        { l: 'd', r: 1 },
        { l: 'dd', r: 29, d: 'day' },
        { l: 'M', r: 1 },
        { l: 'MM', r: 11, d: 'month' },
        { l: 'y' },
        { l: 'yy', d: 'year' }
    ]})
require('dayjs/locale/en')
require('dayjs/locale/fr')
require('dayjs/locale/zh')
require('dayjs/locale/ja')
require('dayjs/locale/ru')
require('dayjs/locale/es')
require('dayjs/locale/de')
require('dayjs/locale/pt')
dayjs.locale($('html').attr('lang'))

export default dayjs
