$(function () {
  function hideHomeGridOpenVideos() {
    $('.game-preview__video').hide()
  }

  function showWantedHomeGridVideo($item) {
    let $wrapper = $item.find('.game-preview__video');
    let src = $wrapper.attr('data-src');
    if (!src) return;
    $wrapper.show();
    if (!$wrapper.find('video').attr('src')) $wrapper.find('video').attr('src',src)
  }

  function toggleHomeGridVideoOn($item) {
    $('.game-preview__video').not($item.find('.game-preview__video')).hide()
    showWantedHomeGridVideo($item)
  }

  $('.game').mouseenter(function (e) {
    toggleHomeGridVideoOn($(this));
  }).mouseleave(function () {
    hideHomeGridOpenVideos();
  }).each(function (i, item) {
    let mc = new Hammer(item);
    mc.on('press', function () {
      toggleHomeGridVideoOn($(item));
    });
    mc.on('pan', function () {
      toggleHomeGridVideoOn($(item));
    });
  })
});
