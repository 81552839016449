import * as debounce from "debounce";

require('slick-carousel')
require('./jquery_passive_events_support')

import dayjs from 'commons/dayjs_locale'
import 'hammerjs'
import 'home_grid_events'

$(function () {
  $('.game p.desc').click(function () {
    var href = $(this).parent().find('.game__link').attr('href');
    window.location.href = href;
  });

  $('.slick-slider--promo-spot').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    centerMode: false,
    rows: 0,
    arrows: false,
    variableWidth: true,
    focusOnSelect: false,
    focusOnChange: false,
    dots: false,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 655,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          arrows: true
        }
      }
    ]
  });

  $('.game__released.client-time').each(function (i,item) {
    let d = dayjs.utc($(item).attr('data-released'),'YYYY-MM-DD HH:mm:ss UTC')
    $(item).find('span').text(d.fromNow())
  })

  $('.filter-results').on('click',function(e) {
    e.preventDefault();
    $('.search-filters').show();
    $(this).hide();
    if (window.matchMedia("(max-width: 1000px)").matches) {
      document.querySelector('.search-filters').scrollIntoView({
        behavior: 'smooth'
      });
    }
  })

  $('.filters-close').on('click',function(e) {
    e.preventDefault();
    $('.search-filters').hide();
    $('.filter-results').css('display', 'flex');
  })

  $('a.search-filters__link, .checkbox-button a, .search-filters__subtitle a').on('click',function(e) {
    $('.search-filters').addClass('search-filters--disabled');
  })

  $('.show-more').on('click',function(e) {
    e.preventDefault();
    $('.checkbox-buttons').toggleClass('show');
  })
});
